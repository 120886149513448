import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { Footer } from '../layouts/Footer';
import { IndexGraph } from './index/IndexGraph';
import { NavbarComponent } from '../layouts/Navbar';

const { pageTitle } = defineMessages({
  pageTitle: {
    id: 'web.main_title',
    defaultMessage: 'TeslaEesti'
  }
});

export const IndexPage: NextPage = React.memo(() => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>{intl.formatMessage(pageTitle)}</title>
      </Head>
      <NavbarComponent />
      <IndexGraph />
      <Footer />
    </>
  );
});
