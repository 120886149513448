import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import classNames from 'classnames';
import style from './Footer.module.scss';

export const FooterLink: React.FC<{
  children: JSX.Element | string;
  to: string;
}> = ({ children, to }) => (
  <li className="py-2">
    <Link legacyBehavior href={to} passHref>
      <a>{children}</a>
    </Link>
  </li>
);

export const FooterLinks: React.FC = () => (
  <ul className={classNames('list-unstyled', style.links)}>
    <FooterLink to="/pricing">
      <FormattedMessage id="web.nav.pricing" defaultMessage="Pricing" />
    </FooterLink>
    <FooterLink to="/privacy-policy">
      <FormattedMessage id="web.footer.privacy-policy" defaultMessage="Privacy Policy" />
    </FooterLink>
    <FooterLink to="/refund-policy">
      <FormattedMessage id="web.footer.refund-policy" defaultMessage="Refund Policy" />
    </FooterLink>
    <FooterLink to="/terms-of-service">
      <FormattedMessage id="web.footer.terms-of-service" defaultMessage="Terms of Service" />
    </FooterLink>
    <FooterLink to="/imprint">
      <FormattedMessage id="web.footer.imprint" defaultMessage="Imprint" />
    </FooterLink>
    {/* <FooterLink to="/blog"> */}
    {/*  <FormattedMessage id="web.nav.blog" defaultMessage="Blog" /> */}
    {/* </FooterLink> */}
    {/* <FooterLink to="/press-kit"> */}
    {/*  <FormattedMessage id="web.nav.press-kit" defaultMessage="Press Kit" /> */}
    {/* </FooterLink> */}
  </ul>
);

export const Footer: React.FC = () => {
  const intl = useIntl();
  return (
    <footer className="container py-5">
      <div className="d-flex justify-content-around flex-column flex-md-row">
        <div className="overflow-hidden">
          <div className="d-flex flex-column text-heading text-center text-md-right mt-5">
            Made by <a href="mailto:b@hern.as">b@hern.as</a>
          </div>
        </div>
      </div>
      <div className={style.copyright}>
        {intl.formatMessage(
          {
            id: 'web.footer.rights',
            defaultMessage: '©{date} TeslaEesti.com'
          },
          { date: new Date().getFullYear() }
        )}
      </div>
    </footer>
  );
};
