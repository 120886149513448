import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Link from 'next/link';

const { logoAlt } = defineMessages({
  logoAlt: {
    id: 'web.nav.logo-alt',
    defaultMessage: 'TeslaEesti.com'
  }
});

export const GenericNavbarBrand: React.FC<{
  dark?: boolean;
}> = () => {
  const intl = useIntl();

  return (
    <Link className="ml-auto ml-sm-auto mr-auto navbar-brand text-light" href="/">
      {intl.formatMessage(logoAlt)}
    </Link>
  );
};
