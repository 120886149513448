import React, { useState } from 'react';
import { GenericNavbar } from './GenericNavbar';

export const NavbarComponent: React.FC<{
  dark?: boolean;
}> = React.memo(({ dark }) => {
  const [isOpen, setIsOpen] = useState(false);

  return <GenericNavbar isOpen={isOpen} setIsOpen={setIsOpen} dark={dark} />;
});
