import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import style from './IndexGraph.module.scss';
import { useHasMounted } from '../../components/hooks/useHasMounted';

export const IndexGraph: React.FC = React.memo(() => {
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (!hasMounted) {
      return;
    }
    const divElement = document.getElementById('viz1702214574263');
    if (divElement === null) {
      return;
    }
    const vizElement = divElement.getElementsByTagName('object')[0];
    if (divElement.offsetWidth > 800) {
      vizElement.style.width = '1000px';
      vizElement.style.height = '1350px';
    } else if (divElement.offsetWidth > 500) {
      vizElement.style.width = '1000px';
      vizElement.style.height = '1350px';
    } else {
      vizElement.style.width = '100%';
      vizElement.style.height = '3300px';
    }
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
    if (!vizElement.parentNode) {
      return;
    }
    vizElement.parentNode.insertBefore(scriptElement, vizElement);
  }, [hasMounted]);
  return (
    <Container>
      <Row>
        <Col>
          <div className="py-3 lead">All Tesla cars currently registered in Estonia:</div>
        </Col>
      </Row>
      <div className={style.container}>
        {hasMounted && (
          <div className="tableauPlaceholder" id="viz1702214574263" style={{ position: 'relative' }}>
            <object className="tableauViz" style={{ display: 'none' }}>
              <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />{' '}
              <param name="embed_code_version" value="3" />
              <param name="path" value="shared&#47;HKJ8G5YYF" />
              <param name="toolbar" value="no" />
              <param
                name="static_image"
                value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;HK&#47;HKJ8G5YYF&#47;1.png"
              />{' '}
              <param name="animate_transition" value="yes" />
              <param name="display_static_image" value="yes" />
              <param name="display_spinner" value="yes" />
              <param name="display_overlay" value="yes" />
              <param name="display_count" value="yes" />
            </object>
          </div>
        )}
      </div>
    </Container>
  );
});
